<template>
  <el-dialog
    title="动态口令登录码"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="480px"
    @closed="visible=false"
  >
    <div style="font-size:12px;color:rgb(255, 104, 123);margin-bottom:20px">说明：登录码根据人员账号生成，需要下载谷歌验证器-扫码添加</div>
    <div style="display: flex;justify-content: center;align-items: center">
      <VueQr v-if="info.googleCodeQrcode" logoSrc="" :text="info.googleCodeQrcode" :size="150" :margin="0"></VueQr>
      <div class="left_fg" style="margin-left: 20px;">
        <p>账号：{{info.googleCodeKey}}</p>
        <p>密钥：{{info.googleCodeSecret}}</p>
      </div>
    </div>
    <span slot="footer"  class="dialog-footer stydata">
      <div class="left_fg">
        <p>下载地址：</p>
        <p>安卓：<span class="copy" @click="copy(info.googleCodeDownloadAndroid)">{{info.googleCodeDownloadAndroid}}</span></p>
        <p>苹果：<span class="copy" @click="copy(info.googleCodeDownloadIos)">{{info.googleCodeDownloadIos}}</span></p>
      </div>
      <el-button type="primary" @click.native="visible=false">关闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
import VueQr from "vue-qr"
import _api from "@/utils/request";
export default {
  name: 'CodeDia',
  components: { VueQr },
  data() {
    return {
      visible: false,
      loading: false,
      companyId: "",
      info: {}
    }
  },
  methods: {
    open(id,type) {
      if (type === 'see') {
        this.companyId = id
        this.seeCode()
      } else {
        this.companyId = id
        this.genCode()
      }
      this.visible = true
    },
    genCode() {
      this.loading = true
      _api.createGoogleCode({
        companyId: this.companyId
      }).then(res => {
        this.info = res.data
        this.$emit('genCodeSuccess')
      }).finally(() => {
        this.loading = false
      })
    },
    seeCode() {
      this.loading = true
      _api.queryGoogleCode({
        companyId: this.companyId
      }).then(res => {
        this.info = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    close() {
      this.visible = false
    },
    confirm() {

    },
    // 下载地址点击复制
    copy(url) {
      const el = document.createElement('textarea');
      el.value = url;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$message.success('复制成功')
    }
  }
}
</script>

<style lang="scss" scoped>
.left_fg{
  font-size: 14px;
  text-align: left;
  >p{
    margin-bottom: 10px;
  }
}
.stydata{
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.copy{
  color: #0981ff;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}
</style>